import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual } from 'recompose'
import Dropzone from 'react-dropzone'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import classnames from 'classnames'
import { addField, translate } from 'ra-core'

import { Labeled } from 'react-admin'
import FileInputPreview from './FileInputPreview'
import sanitizeRestProps from './sanitizeRestProps'
import ImageResourcePicker from './ImageResourcePicker'
import OpenImageLibraryButton from './OpenImageLibraryButton'

const libraryButtonStyles = {
    root: {
        width: "6rem",
        height: "6rem",
        boxSizing: 'content-box',
        padding: '0.5rem',
        border: "0.1rem solid #2196f3",
        marginRight: '0.8rem'
    }
}

const styles = {
    container: {
        display: "flex"
    },
    dropZone: {
        background: '#efefef',
        cursor: 'pointer',
        padding: '0.5rem',
        textAlign: 'center',
        color: '#999',
        width: "6rem",
        height: "6rem",
        borderRadius: "0.5rem",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "0.1rem dashed #bbb"
    },
    preview: {
        marginBottom: '0.8rem',
        border: "0.05rem solid #aaa",
        height: "10rem",
        borderRadius: "0.4rem",
        overflow: "hidden",
        position: 'relative',
        display: 'inline-block',
        '& button': {
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            minWidth: '2rem',
            opacity: 0,
        },
        '&:hover button': {
            opacity: 1,
        },
        '& img': {
            "object-fit": "contain",
            height: "100%",
            width: "100%",
            margin: 0
        }
    },
    root: { width: '100%' },
};

export class FileInput extends Component {

    static propTypes = {
        accept: PropTypes.string,
        children: PropTypes.element,
        classes: PropTypes.object,
        className: PropTypes.string,
        disableClick: PropTypes.bool,
        id: PropTypes.string,
        input: PropTypes.object,
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        labelMultiple: PropTypes.string,
        labelSingle: PropTypes.string,
        maxSize: PropTypes.number,
        minSize: PropTypes.number,
        multiple: PropTypes.bool,
        options: PropTypes.object,
        resource: PropTypes.string,
        source: PropTypes.string,
        translate: PropTypes.func.isRequired,
        placeholder: PropTypes.node,
    };

    static defaultProps = {
        labelMultiple: 'ra.input.image.upload_several',
        labelSingle: 'ra.input.image.upload_single',
        multiple: false,
        onUpload: () => {},
        translate: id => id,
    };

    constructor(props) {
        super(props);
        let files = props.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.state = {
            files: files.map(this.transformFile),
        };
    }

    componentWillReceiveProps(nextProps) {
        let files = nextProps.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.setState({ files: files.map(this.transformFile) });
    }

    onDrop = files => {
        const updatedFiles = this.props.multiple
            ? [...this.state.files, ...files.map(this.transformFile)]
            : [...files.map(this.transformFile)];

        this.setState({ files: updatedFiles });

        if (this.props.multiple) {
            // Use onBlur to ensure redux-form set the input as touched
            this.props.input.onBlur(updatedFiles);
        } else {
            this.props.input.onBlur(updatedFiles[0]);
        }
    };

    onRemove = file => () => {
        const filteredFiles = this.state.files.filter(
            stateFile => !shallowEqual(stateFile, file)
        );

        this.setState({ files: filteredFiles });

        // Use onBlur to ensure redux-form set the input as touched
        if (this.props.multiple) {
            this.props.input.onBlur(filteredFiles);
        } else {
            this.props.input.onBlur(null);
        }
    };

    onSelect = (url) => {
        const file = this.transformFile(url)
        this.setState({ files: [file]});
        this.props.input.onBlur(file);
    }

    // turn a browser dropped file structure into expected structure
    transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = React.Children.toArray(
            this.props.children
        )[0].props;

        const transformedFile = {
            rawFile: file,
            [source]: file.preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };

    label() {
        const {
            translate,
            placeholder,
            labelMultiple,
            labelSingle,
        } = this.props;

        if (placeholder) {
            return placeholder;
        }

        if (this.props.multiple) {
            return <p>{translate(labelMultiple)}</p>;
        }

        return <p>{translate(labelSingle)}</p>;
    }

    render() {
        const {
            accept,
            children,
            classes = {},
            className,
            disableClick,
            id,
            isRequired,
            label,
            maxSize,
            meta,
            minSize,
            multiple,
            resource,
            source,
            translate,
            options = {},
            ...rest
        } = this.props;

        return (
            <Labeled
                id={id}
                label={label}
                className={classnames(classes.root, className)}
                source={source}
                resource={resource}
                isRequired={isRequired}
                meta={meta}
                {...sanitizeRestProps(rest)}
            >
                <React.Fragment>
                {meta && meta.touched && meta.error && (
                    <FormHelperText>{translate(meta.error)}</FormHelperText>
                )}
                {children && (
                    <div>
                        {this.state.files.map((file, index) => (
                            <FileInputPreview
                                key={index}
                                file={file}
                                onRemove={this.onRemove(file)}
                                className={classes.preview}
                            >
                                {React.cloneElement(children, {
                                    record: file
                                })}
                            </FileInputPreview>
                        ))}
                    </div>
                )}
                <div className={classes.container}>
                    <OpenImageLibraryButton buttonClasses={libraryButtonStyles}>
                        <ImageResourcePicker onSelect={this.onSelect.bind(this)} />
                    </OpenImageLibraryButton>
                    <Dropzone
                        onDrop={this.onDrop}
                        accept={accept}
                        disableClick={disableClick}
                        maxSize={maxSize}
                        minSize={minSize}
                        multiple={multiple}
                        className={classes.dropZone}
                        {...options}
                        inputProps={{ id, ...options.inputProps }}
                    >
                        {this.label()}
                    </Dropzone>
                </div>
                </React.Fragment>
            </Labeled>
        );
    }
}

export default compose(
    addField,
    translate,
    withStyles(styles)
)(FileInput);