import {
    crudGetOneReference
} from '../actions/GetOneReference'
import React from 'react'
import {
    crudUpdate,
    startUndoable as startUndoableAction
} from 'react-admin'
import { withTranslate, REDUX_FORM_NAME } from 'ra-core'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import compose from 'recompose/compose'
import { EditView } from 'ra-ui-materialui'
import inflection from 'inflection'

function mapStateToProps(state, props) {
    return {
        id: props.id,
        record: state.admin.resources[props.resource]
            ? state.admin.resources[props.resource].data[props.id]
            : null,
        isLoading: state.admin.loading > 0,
        version: state.admin.ui.viewVersion,
    }
}

class UnconnectedGetOneReferenceEditController extends React.Component {
    componentDidMount() {
        this.updateData()
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.id !== nextProps.id ||
            nextProps.version !== this.props.version
        ) {
            this.props.resetForm(REDUX_FORM_NAME)
            this.updateData(nextProps.resource, nextProps.id)
        }
    }

    defaultRedirectRoute() {
        return 'list'
    }

    updateData(
        resource = this.props.resource,
        target = this.props.target,
        id = this.props.id
    ) {
        this.props.crudGetOneReference(
            resource,
            target,
            id,
            this.props.basePath
        )
    }

    save = (data, redirect) => {
        const {
            undoable = true,
            startUndoable,
            dispatchCrudUpdate,
        } = this.props
        if (undoable) {
            startUndoable(
                crudUpdate(
                    this.props.resource,
                    this.props.id,
                    data,
                    this.props.record,
                    this.props.basePath,
                    redirect
                )
            )
        } else {
            dispatchCrudUpdate(
                this.props.resource,
                this.props.id,
                data,
                this.props.record,
                this.props.basePath,
                redirect
            )
        }
    }

    render() {
        const {
            basePath,
            children,
            id,
            isLoading,
            record,
            resource,
            translate,
            version,
        } = this.props

        if (!children) {
            return null
        }

        const resourceName = translate(`resources.${resource}.name`, {
            smart_count: 1,
            _: inflection.humanize(inflection.singularize(resource)),
        })
        const defaultTitle = translate('ra.page.edit', {
            name: `${resourceName}`,
            id,
            record,
        })

        return children({
            isLoading,
            defaultTitle,
            save: this.save,
            resource,
            basePath,
            record,
            redirect: this.defaultRedirectRoute(),
            translate,
            version,
        })
    }
}

const GetOneReferenceEditController = compose(
    connect(
        mapStateToProps,
        {
            crudGetOneReference,
            dispatchCrudUpdate: crudUpdate,
            startUndoable: startUndoableAction,
            resetForm: reset,
        }
    ),
    withTranslate
)(UnconnectedGetOneReferenceEditController)

const GetOneReferenceEdit = props => (
    <GetOneReferenceEditController {...props}>
        {controllerProps => <EditView {...props} {...controllerProps} />}
    </GetOneReferenceEditController>
)

export default GetOneReferenceEdit