import React from 'react'
import {
    SimpleForm,
    Toolbar,
    Datagrid,

    SaveButton,

    SelectField,
    TextField,
    NumberField,
    ImageField,
    ArrayField,

    BooleanInput,

    required,

    crudUpdate
} from 'react-admin'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { FirebasePath, FirebasePathComponent } from '../firebase/model'
import PropTypes from 'prop-types'
import isString from "lodash/isString"
import GetOneReferenceEdit from './GetOneReferenceEdit'
import LocalizableTextField from './LocalizableTextField'
import BooleanField from './BooleanField'
import generateStats from '../utils/generateStats'
import get from 'lodash/get'

const PresetBuffEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            submitOnEnter={true}
            disabled={props.pristine || props.invalid}
        />
    </Toolbar>
)

class PresetBuffEditView extends React.Component {

    handleSave = values => {
        const { gameId, presetId, crudUpdate } = this.props

        //Add the right labels to each choice
        const choices = values.info.choices
        const labels = "ABC".split("")
        for (let i = 0; i < choices.length; i++) {
            choices[i]["label"] = labels[i % labels.length]
        }

        if (values.stats === undefined || Object.keys(values.stats).length !== choices.length)  {
            //Add stats with empty values
            values["stats"] = generateStats(choices.length, 0)
        }

        if (crudUpdate && isString(gameId) && isString(presetId)) {
            crudUpdate(
                "preset_buffs",
                presetId,
                values,
                {},
                '',
                `/games/${gameId}/show/1`
            )
        }
    }

    render() {
        const {gameId, presetId, record, crudUpdate, ...rest} = this.props
        const isResolved = get(record, "resolved") || false
        return (
            <SimpleForm
                record={record}
                {...rest}
                save={this.handleSave}
                toolbar={ <PresetBuffEditToolbar/> }
                defaultValue={{
                    _basePath: new FirebasePath([new FirebasePathComponent("games", gameId)])
                }}
            >
                {
                    isResolved
                    ? (
                        <BooleanField
                            source="is_enabled"
                            defaultValue={true}
                        />
                    )
                    : (
                        <BooleanInput
                            source="is_enabled"
                            defaultValue={true}
                            validate={[required()]}
                        />
                    )
                }

                <Divider />
                <TextField
                    source="author.name"
                    label="Author Name"
                />
                <ImageField
                    source="author.image"
                    label="Author Image"
                />
                <LocalizableTextField
                    source="info.question"
                    label="Question"
                />
                <NumberField
                    source="info.countdown"
                    label="Countdown"
                />
                <SelectField
                    source="game_state"
                    label="Can be sent on"
                    choices={[
                        { id: 'Pre Match', name: 'Pre Match' },
                        { id: 'Ongoing', name: 'Ongoing' },
                        { id: 'Post Match', name: 'Post Match' },
                    ]}
                />
                <BooleanField source="can_be_sent_by_users" />
                <ArrayField
                    source="info.choices"
                    label="Choices (At least two)"
                >
                    <Datagrid>
                        <LocalizableTextField
                            source="text"
                            label="Text"
                            hideLabel
                        />
                        <NumberField
                            source="points_value"
                        />
                        <ImageField
                            source="image"
                            label="Image"
                        />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        )
    }

}

PresetBuffEditView.defaultProps = {
    gameId: '',
    presetId: ''
}

PresetBuffEditView.propTypes = {
    gameId: PropTypes.string.isRequired,
    presetId: PropTypes.string.isRequired,
    crudUpdate: PropTypes.func.isRequired
}

const PresetBuffUpdateView = connect(
    null,
    { crudUpdate }
)(PresetBuffEditView)

export const PresetBuffEdit = ({
    gameId,
    presetId,
    staticContext,
    ...rest
}) => {
    return (
        <GetOneReferenceEdit
            {...rest}
            resource="preset_buffs"
            target={{_basePath: new FirebasePath([new FirebasePathComponent("games", gameId)])}}
            id={presetId}
        >
            <PresetBuffUpdateView gameId={gameId} presetId={presetId} />
        </GetOneReferenceEdit>
    )
}