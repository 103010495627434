import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"

const model_prefix = ""
const app = firebase.initializeApp({
  apiKey: "AIzaSyBdGTs0WX5buVtyYWa4EX8Q3N0hzV-YGSg",
  authDomain: "buffuppolitics.firebaseapp.com",
  databaseURL: "https://buffuppolitics.firebaseio.com",
  projectId: "buffuppolitics",
  storageBucket: "buffuppolitics.appspot.com",
  messagingSenderId: "212167543378",
  appId: "1:212167543378:web:1155a325d5fd80bcb52d8b",
  measurementId: "G-GMXZ69TP49"
})

const auth = app.auth()
const db = app.firestore()
const storage = app.storage()
const FieldValue = firebase.firestore.FieldValue
const TimeStamp = firebase.firestore.Timestamp

export {
    auth,
    model_prefix,
    db,
    storage,
    FieldValue,
    TimeStamp
}
