import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';
import { auth, db } from './firebase/config'

const isAdmin = (database, uid) => {
  return database.doc(`/users/${uid}`)
    .get()
    .then((q) => {
      const userData = q.data()
      if (userData.roles && userData.roles.admin && userData.roles.admin === true) {

        localStorage.setItem('token', uid)

        return Promise.resolve();
      }

      return Promise.reject('This username/password does not have permissions.');
    })
}

export default (type, params) => {

  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return auth
      .signInWithEmailAndPassword(username, password)
      .then((cred) => {
        const meta = cred.user.metadata

        return isAdmin(db, cred.user.uid)

      })
      .catch(err => {
        if (err.code === 'auth/user-not-found') {
          return Promise.reject('Wrong credentials')
        }

        return Promise.reject(err.message)
      })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' })
  }
  return Promise.reject('Unkown method');
};
