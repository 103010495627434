import React from 'react'
import { Typography } from '@material-ui/core'
import FalseIcon from '@material-ui/icons/Clear'
import TrueIcon from '@material-ui/icons/Done'
import PropTypes from 'prop-types'
import sanitizeRestProps from './sanitizeRestProps'

class BooleanFunctionField extends React.Component {
    render() {

        const {
            render,
            record,
            className,
            ...rest
        } = this.props

        return (
            <Typography
                component="span"
                variant="body1"
                className={className}
                {...sanitizeRestProps(rest)}
            >
                {
                    render(record) === true
                    ? (<TrueIcon data-testid="true" />)
                    : (<FalseIcon data-testid="false" />)
                }
            </Typography>
        )
    }
}

BooleanFunctionField.defaultProps = {
    addLabel: true,
    record: {}
}

BooleanFunctionField.propTypes = {
    ...Typography.propTypes,
    record: PropTypes.object,
    render: PropTypes.func.isRequired
}

export default BooleanFunctionField