import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isString from 'lodash/isString'
import Typography from '@material-ui/core/Typography'
import sanitizeRestProps from './sanitizeRestProps'
import { getLocalizedString, getLocalizedFlags } from '../utils/localized'
import InputLabel from '@material-ui/core/InputLabel'
import { FieldTitle } from 'ra-core'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = theme =>
    createStyles({
        label: {
            position: 'relative',
        },
        container: {
            marginTop: '1rem',
            marginBottom: '0.5rem',
            marginLeft: 0,
            marginRight: 0,
            display: 'inline-flex',
            padding: 0,
            position: 'relative',
            minWidth: 0,
            flexDirection: 'column'
        }
    });

class LocalizableTextField extends React.Component {

    render() {
        const { source, record, label, hideLabel, resource, classes, ...rest } = this.props
        const values = get(record, source)
        const title = values ? getLocalizedString(values) : undefined
        const flags = values ? getLocalizedFlags(values) : undefined

        return (
            <div className={
                (label && !hideLabel) ? classes.container : undefined
            }>
                { label && !hideLabel && (
                        <InputLabel htmlFor={record.id} shrink className={classes.label}>
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                        />
                        </InputLabel>
                    )
                }
                <Typography
                    component="span"
                    body1="body1"
                    {...sanitizeRestProps(rest)}
                >
                    {isString(title) ? title : "N/A"}
                </Typography>
                {
                    isString(flags) && (
                        <Typography
                            component="span"
                            variant="caption"
                            color="inherit"
                        >({flags})</Typography>
                    )
                }
            </div>
        )
    }

}

LocalizableTextField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired
}

export default withStyles(styles)(LocalizableTextField)