import {
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE_MANY,
  DELETE
} from 'ra-core'
import { UPDATE_THEN_CREATE } from '../actions/UpdateThenCreate'
import { model_prefix, db, storage, TimeStamp } from './config'
import { FirebasePath, FirebasePathComponent } from '../firebase/model'
import clonedeep from 'lodash/cloneDeep'
import isObjectLike from 'lodash/isObjectLike'
import get from 'lodash/get'
import deburr from 'lodash/deburr'
import uuid from 'uuid/v5'
import mapValuesAsync from '../utils/mapValuesAsync'
import mapKeys from 'lodash/mapKeys'

/**
 * @param {any} data
 * @returns {Promise<any>}
 */
async function handleFileUploads(data) {
  if (!isObjectLike(data)) {
    return data
  }

  return mapValuesAsync(data, async (value) => {
    const rawFile = get(value, "rawFile")
    if (rawFile instanceof File) {
      //TODO: check the file type, and create a good name
      const originalName = rawFile.name || ""
      const fileName = uuid(originalName + new Date(), "37e98d9d-11db-45e7-851d-127a0f610093")
      return await storage
      .ref("images")
      .child(fileName)
      .put(rawFile, {
        customMetadata: {
          "original_name": originalName
        }
      })
      .then(snapshot =>
        snapshot.ref
        .getDownloadURL()
        .then(url => ({url: url, fullPath: snapshot.ref.fullPath}))
      )
      .then(({url, fullPath}) =>
        db
        .collection("image_uploads") //no prefix here since storage is shared between environments
        .add({
          fullPath: fullPath,
          name: originalName,
          searchable_name: deburr(originalName.toLowerCase()),
          url: url
        })
        .then(_ => url)
      )
    } else if (isObjectLike(value)) {
      return await handleFileUploads(value)
    } else {
      return value
    }
  })
}

/**
 * @param {FirebasePath} firebasePath
 * @param {string} prefix
 * @returns {string}
 */
function pathToString(firebasePath) {
  let path = model_prefix
  let isFirst = true
  firebasePath.components.forEach(c => {
    if (isFirst) {
      isFirst = false
    } else {
      path += "/"
    }
    path += `${c.name}`
    const id = c.id
    if (id !== undefined) {
      path += `/${c.id}`
    }
  })
  return path
}

/**
 * @param {firebase.firestore.DocumentSnapshot} doc
 * @returns {any}
 */
function documentToData(doc) {
  let data = doc.data()
  data.id = doc.id
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof TimeStamp) {
      data[key] = value.toDate()
    }
  })
  return data
}

/**
 * @param {any} data
 * @returns {any}
 */
function sanitizeData(data) {
  let clonedData = clonedeep(data)
  delete clonedData.id
  delete clonedData._basePath
  return clonedData
}

/**
 * @param {string} resource
 * @param {any} filter
 * @returns {string}
 */
function transformFilterKeysForResource(resource, filter) {
  return mapKeys(filter, (_, k) => {
    if (resource === "users" && k === "q") {
      return "info.name"
    }
    return k
  })
}

/**
 * @param {firebase.firestore.CollectionReference} collectionRef
 * @param {string} resource
 * @param {any|undefined} sort
 * @param {any|undefined} filter
 * @param {any|undefined} pagination
 * @returns {Promise<firebase.firestore.QuerySnapshot>}
 */
function getWithSortingParameters(collectionRef, resource, sort, filter, pagination) {
      let query = undefined
      if (
        sort != null
        && sort !== undefined
        && sort.field !== "id"
      ) {
        const { field, order } = sort
        query = collectionRef.orderBy(field, order.toLowerCase())
      }

      if (filter) {
        const transformedFilters = transformFilterKeysForResource(resource, filter)

        Object
        .entries(transformedFilters)
        .forEach(([key, value]) => {
          if (query) {
            // "Hack" to implement the query "all objects that start with value"
            // See: https://firebase.google.com/docs/database/rest/retrieve-data#range-queries
            query = query.orderBy(key).startAt(value).endAt(value+"\uf8ff")
          } else {
            query = collectionRef.orderBy(key).startAt(value).endAt(value+"\uf8ff")
          }
        })
      }

      if (query) {
        return query.get()
      } else {
        return collectionRef.get()
      }
}

/**
 * @param {firebase.firestore.QueryDocumentSnapshot} documents
 * @returns {any}
 */
function documentsToData(documents) {
  return documents.map(d => documentToData(d))
}

/**
 * @param {string} type
 * @param {string} resource
 * @param {any} params
 * @returns {Promise<any>}
 */
function storageProvider(type, resource, params) {
  // console.log(`Storage ${type} ${resource} ${JSON.stringify(params)}`)
  const query = get(params, "filter.q")

  if (type === GET_LIST && query) {

    return db
    .collection(`image_uploads`)
    .orderBy("searchable_name")
    .startAt(query)
    .endAt(query+"\uf8ff")
    .get()
    .then(result => ({
      data: result
        .docs
        .map(doc => {
          const data = doc.data()
          return {
            id: data.fullPath,
            name: data.name,
            getURL: () => data.url
          }
        }
      ),
      total: result.size
    }))

  } else if (type === GET_LIST) {

    const previousData = get(params, "pagination.previousData")
    const imagesRef = storage.ref(resource)
    return imagesRef.list({
      maxResults: 50,
      pageToken: get(params, "pagination.nextPageToken")
    })
    .then(result => {
      let items = result.items.map(i => ({
        id: i.fullPath,
        name: i.name,
        getMetadata: () => i.getMetadata(),
        getURL: () => i.getDownloadURL()
      }))

      if (items.length > 0) {
        items[items.length - 1].nextPageToken = result.nextPageToken
      }

      if (previousData) {
        items = previousData.concat(items)
      }

      return {
        data: items,
        total: items.length
      }
    })
    .catch(error => {
      if (previousData) {
        return {
          data: previousData,
          total: previousData.length
        }
      } else {
        // console.log(error)
        throw error
      }
    })

  } else {
    throw Error("Provide type not implemented")
  }
}

/**
 * @param {string} type
 * @param {string} resource
 * @param {any} params
 * @param {firebase.firestore.WriteBatch|undefined} batch
 * @returns {Promise<any>}
 */
async function firestoreProvider(
  type,
  resource,
  params,
  batch = undefined
) {
  // console.log(`Firestore ${type} ${resource} ${JSON.stringify(params)}`)

  if (type === GET_LIST) {

    return getWithSortingParameters(
      db.collection(`${model_prefix}${resource}`),
      resource,
      params.sort,
      params.filter
    )
    .then(result => ({ data: documentsToData(result.docs), total: result.size }))

  } else if (type === GET_ONE) {

    const firebasePath = get(params, "target._basePath")
    if (firebasePath) {
      const path = pathToString(firebasePath.pushed(new FirebasePathComponent(resource)))
      return db
      .collection(path)
      .doc(params.id)
      .get()
      .then(d => {
        return { data: documentToData(d) }
      })
    } else {
      return db
      .collection(`${model_prefix}${resource}`)
      .doc(params.id)
      .get()
      .then(d => ({ data: documentToData(d) }))
    }

  } else if (type === GET_MANY_REFERENCE) {

    const firebasePath = get(params, "target._basePath") || new FirebasePath()
    const path = pathToString(firebasePath.pushed(new FirebasePathComponent(resource)))

    return getWithSortingParameters(
      db.collection(path),
      resource,
      params.sort,
      params.filter
    )
    .then(result => ({ data: documentsToData(result.docs), total: result.size }))

  } else if (type === GET_MANY) {

    const ids = params.ids
    const firebasePath = get(params, "target._basePath") || new FirebasePath()
    const path = pathToString(firebasePath.pushed(new FirebasePathComponent(resource)))

    return Promise.all(
      ids.map(id => db.collection(path).doc(id).get())
    ).then(results => ({ data: documentsToData(results), total: results.length }))

  } else if (type === CREATE) {

    const originalData = params.data
    let basePath = undefined
    if (originalData._basePath !== undefined) {
      basePath = originalData._basePath
    } else {
      basePath = new FirebasePath()
    }

    const path = pathToString(basePath.pushed(new FirebasePathComponent(resource)))
    const collectionRef = db.collection(path)
    const createData = await handleFileUploads(sanitizeData(originalData))

    if (batch === undefined) {
      return collectionRef
      .add(createData)
      .then(r => r.get())
      .then(d => ({ data: documentToData(d) }))
    } else {
      const newDocRef = collectionRef.doc()
      batch.set(newDocRef, createData)
      return Promise.resolve(newDocRef)
    }

  } else if (type === DELETE) {

    const id = params.id
    return db.collection(`${model_prefix}${resource}`).doc(id).delete().then(_ => ({ data: {id: id} }))

  } else if (type === DELETE_MANY) {

    const ids = params.ids
    const batch = db.batch()
    ids.map(id => db.collection(`${model_prefix}${resource}`).doc(id)).forEach(d => batch.delete(d))
    return batch.commit().then(_ => ({ data: ids }))

  } else if (type === UPDATE) {

    const id = params.id
    const newData = params.data
    let basePath = undefined
    if (newData._basePath !== undefined) {
      basePath = newData._basePath
    } else {
      basePath = new FirebasePath()
    }

    const path = pathToString(basePath.pushed(new FirebasePathComponent(resource)))
    const docRef = db.collection(path).doc(id)
    const updateData = await handleFileUploads(sanitizeData(newData))

    if (batch === undefined) {
      return docRef.update(updateData).then(d => {
        return ({ id: id, data: newData })
      })
    } else {
      batch.update(docRef, updateData)
      return Promise.resolve()
    }

  } else if (type === UPDATE_THEN_CREATE) {

    const batch = db.batch()
    await providerFunction(UPDATE, resource, params.update, batch)
    const newDocRef = await providerFunction(CREATE, resource, params.create, batch)
    await batch.commit()
    const doc = await newDocRef.get()
    return { data: documentToData(doc) }

  } else {
    throw Error("Provide type not implemented")
  }
}


/**
 * @param {string} type
 * @param {string} resource
 * @param {any} params
 * @param {firebase.firestore.WriteBatch|undefined} batch
 * @returns {Promise<any>}
 */
async function providerFunction(
  type,
  resource,
  params,
  batch = undefined
) {
  if (resource === "images") {
    return storageProvider(type, resource, params)
  } else {
    return firestoreProvider(type, resource, params, batch)
  }
}

export default providerFunction
