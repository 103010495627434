import get from 'lodash/get'
import isString from 'lodash/isString'
import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import localeEmoji from 'locale-emoji'

const defaultPreferredLanguages = [
    "en_GB",
    "en_US",
    "en"
]

const DefaultLocales = [
    "en_US",
    "zh_HANS",
    "ru-RU",
    "fr-FR",
    "es_ES",
    "en_GB",
    "de-DE",
    "pt_BR",
    "en_CA",
    "es_MX",
    "it_IT",
    "ja_JP"
]

/**
 * @param {any} obj
 * @returns {String|undefined}
 */
function getLocalizedString(obj) {
    const preferredLanguage = (
        get(window, "navigator.language")
        || get(navigator, "language")
        || get(navigator, "userLanguage")
    ).replace("-", "_")

    let preferredLanguages = undefined

    if (isString(preferredLanguage)) {
        preferredLanguages = [preferredLanguage, ...defaultPreferredLanguages]
    } else {
        preferredLanguages = [...defaultPreferredLanguages]
    }

    const language = preferredLanguages.find(key => get(obj, key))

    if (isString(language)) {
        return get(obj, language)
    }

    return values(obj)[0]
}

/**
 * @param {any} obj
 * @returns {String|undefined}
 */
function getLocalizedFlags(obj) {
    const keys = sortBy(Object.keys(obj) || [], (k) => DefaultLocales.indexOf(k))
    return keys.map(localeEmoji).join(", ")
}

export {
    getLocalizedString,
    getLocalizedFlags,
    DefaultLocales
}