import { GET_ONE } from 'ra-core'
export const GET_ONE_REFERENCE = "GET_ONE_REFERENCE"
export const crudGetOneReference = (
  resource,
  target,
  id,
  basePath,
  refresh = true
) => ({
  type: GET_ONE_REFERENCE,
  payload: { target, id },
  meta: {
      resource: resource,
      fetch: GET_ONE,
      basePath,
      onFailure: {
          notification: {
              body: 'ra.notification.http_error',
              level: 'warning',
          },
          redirectTo: 'list',
          refresh
      },
  },
})