import { model_prefix, db, FieldValue } from './config'
import generateStats from '../utils/generateStats'

/**
 * @param {string} game_id
 * @param {string} preset
 * @returns {Promise<void>}
 */
async function publishPreset(game_id, preset_id) {
    const gameRef = db.collection('games').doc(game_id)
    const presetBuffRef = gameRef.collection("preset_buffs").doc(preset_id)

    return presetBuffRef.get().then(doc => {
        const info = doc.get("info")
        const author = doc.get("author") || null
        const stats = generateStats(info.choices.length, 0)

        return gameRef.collection("sent_buffs").add({
            "info": info,
            "stats": stats,
            "resolved": false,
            "created_at": FieldValue.serverTimestamp(),
            "author": author,
            "preset_id": doc.id
        })
    })
}

/**
 * @param {string} game_id
 * @param {string} preset_id
 * @param {number} right_answer_index
 * @returns {Promise<void>}
 */
async function resolvePreset(game_id, preset_id, right_answer_index) {
    const game = db.collection(`games`).doc(game_id)

    const presetBuffRef = game.collection("preset_buffs").doc(preset_id)
    const presetBuffData = (await presetBuffRef.get()).data()

    return presetBuffRef.update({is_enabled: false, resolved: true}).then( _ => {
        return db
        .collectionGroup("sent_buffs")
        .where("preset_id", "==", preset_id)
        .where("resolved", "==", false)
        .get()
    })
    .then(async result => {
        for (const sentBuff of result.docs) {
            const choices = sentBuff.get("info.choices")
            if (!Array.isArray(choices) || choices[right_answer_index] === undefined ) {
                continue
            }

            const reward = choices[right_answer_index].points_value || 0
            const batch = db.batch()

            //create event
            batch.update(sentBuff.ref, {resolved: true})
            batch.set(game.collection("events").doc(sentBuff.id), {
                "buff": sentBuff.data(),
                "preset": presetBuffData,
                "right_answer_index": right_answer_index
            })

            const users = (await sentBuff.ref.collection("answered_by").get()).docs

            for (const user of users) {
                const userRef = user.ref
                const answeredBuffRef = db.collection(`users`).doc(user.id).collection("answered_buffs").doc(`${game.id}_${sentBuff.id}`)
                const answeredBuff = await answeredBuffRef.get()
                const answered_index = answeredBuff.get("answer_index")
                const answeredRight = answered_index === right_answer_index

                batch.update(answeredBuffRef, {
                    "points": answeredRight ? reward : 0,
                    "answered_right": answeredRight
                })

                if (answeredRight) {
                    const userInfo = user.data()
                    const increment = FieldValue.increment(reward)
                    const gameLeaderboardRef = game.collection("leaderboard").doc(user.id)
                    const leaderboardRef = db.collection(`leaderboard`).doc(user.id)
                    batch.set(userRef, {
                        "points": increment
                    }, { merge: true })
                    batch.set(gameLeaderboardRef, {
                        "info": userInfo,
                        "points": increment
                    }, { merge: true })
                    batch.set(leaderboardRef, {
                        "info": userInfo,
                        "points": increment
                    }, { merge: true })
                }
            }

            await batch.commit()
        }

        return Promise.resolve()
    })
}

export {
    publishPreset,
    resolvePreset
}
