import React, { Fragment } from 'react'
import {
    List,

    Create,
    Edit,

    ReferenceArrayField,
    SingleFieldList,
    ChipField,

    ReferenceArrayInput,
    CheckboxGroupInput,
    SimpleForm,

    Datagrid,

    required,
    minLength
} from 'react-admin'
import LocalizableTextInput from '../components/LocalizableTextInput'
import LocalizableTextField from '../components/LocalizableTextField'
import icon from '@material-ui/icons/FormatListBulleted'

export const GameSetIcon = icon

export const GameSetList = props => (
  <Fragment>
    <List {...props} title="Lists" bulkActionButtons={false} pagination={null}>
        <Datagrid rowClick="edit">
            <LocalizableTextField source="name" label="Name" hideLabel />
            <ReferenceArrayField label="Games" reference="games" source="games">
                <SingleFieldList>
                    <ChipField source="internal_name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
  </Fragment>
)

const GameSetEditView = (props) => (
    <SimpleForm>
        <LocalizableTextInput
            source="name"
            label="Name"
            validate={[required()]}
        />
        <ReferenceArrayInput
            source="games"
            reference="games"
            label="Games"
            validate={[required(), minLength(1)]}
        >
            <CheckboxGroupInput optionText={<LocalizableTextField source="name" label="Name" hideLabel />} />
        </ReferenceArrayInput>
    </SimpleForm>
)

export const GameSetCreate = props => (
    <Create {...props} title="New List" redirect="list">
        {GameSetEditView()}
    </Create>
)

export const GameSetEdit = props => (
    <Edit {...props}  redirect="list">
        {GameSetEditView()}
    </Edit>
)
