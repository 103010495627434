import set from 'lodash/set'

/**
 * @param {number} length
 * @param {any} value
 * @returns {any}
 */
export default (length, value) => {
    let stats = {}
    for (let i = 0; i < length; i++) {
        set(stats, `${i}`, value)
    }
    return stats
}