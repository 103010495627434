import React from 'react'
import {
    List,

    Datagrid,

    TextField,
    NumberField
} from 'react-admin'
import Icon from '@material-ui/icons/FormatListNumbered'

export const LeaderboardIcon = Icon

export const LeaderboardList = props => (
    <List
        {...props}
        title="Leaderboard"
        sort={{ field: 'points', order: 'DESC' }}
        bulkActionButtons={false}
        pagination={null}
    >
        <Datagrid>
            <TextField source="info.name" label="User Name" sortable={false} />
            <NumberField source="points" label="Trophies" sortable={false} />
        </Datagrid>
    </List>
)