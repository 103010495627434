import React from 'react'
import PropTypes from 'prop-types'
import {
  Create,

  SimpleForm,
  Toolbar,

  SaveButton,

  TextInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  ArrayInput,
  FileField,

  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  REDUX_FORM_NAME
} from 'react-admin'
import SimpleFormIterator from './SimpleFormIterator'
import Divider from '@material-ui/core/Divider'
import { crudUpdateThenCreate } from '../actions/UpdateThenCreate'
import isString from 'lodash/isString'
import { get, set, find } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import isObject from 'lodash/isObject'
import { FirebasePath, FirebasePathComponent } from '../firebase/model'
import { connect } from 'react-redux'
import LocalizableTextInput from '../components/LocalizableTextInput'
import ImageInput from './ImageInput'
import ImageField from './ImageField'
import generateStats from '../utils/generateStats'
import { change } from 'redux-form'

const PresetBuffCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Save"
      submitOnEnter={true}
      disabled={props.invalid}
    />
    {/*
            <SaveButton
                label="Save and Send"
                icon={<IconPublish/>}
                color="secondary"
                redirect={false}
                submitOnEnter={false}
                disabled={props.pristine || props.invalid}
            />
        */}
  </Toolbar>
)

const avatars = [
  {
    id: 'Adam Price',
    name: 'Adam Price',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/adam-price_jvchem.jpg'
  },
  {
    id: 'Boris Johnson',
    name: 'Boris Johnson',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/boris-johnson_ep6cti.jpg'
  },
  {
    id: 'Emma Barnett',
    name: 'Emma Barnett',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/emma-barnett_r3uba5.jpg'
  },
  {
    id: 'Fiona Bruce',
    name: 'Fiona Bruce',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/fiona-bruce_ishogv.jpg'
  },
  {
    id: 'Jeremy Corbyn',
    name: 'Jeremy Corbyn',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/jeremy-corbyn_m3pgjg.jpg'
  },
  {
    id: 'Jonathan Bartley',
    name: 'Jonathan Bartley',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393694/politics/jonathan-bartley_zvpogd.png'
  },
  {
    id: 'Jo Swinson',
    name: 'Jo Swinson',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393694/politics/jo-swinson_kyuyev.jpg'
  },
  {
    id: 'Julie Etchingham',
    name: 'Julie Etchingham',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393694/politics/julie-etchingham_bzczui.jpg'
  },
  {
    id: 'Nick Robinson',
    name: 'Nick Robinson',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/nick-robinson_aaq1km.jpg'
  },
  {
    id: 'Nicola Sturgeon',
    name: 'Nicola Sturgeon',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393694/politics/nicola-sturgeon_ed2xei.jpg'
  },
  {
    id: 'Nigel Farage',
    name: 'Nigel Farage',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/nigel-farage_zyqhnj.jpg'
  },
  {
    id: 'Sian Berry AM',
    name: 'Sian Berry AM',
    url: 'https://res.cloudinary.com/dtgno0lg2/image/upload/v1574393695/politics/sian-berry-am_oudshn.jpg'
  },
  {
    id: 'Politics Buff',
    name: 'Politics Buff',
    url: 'https://firebasestorage.googleapis.com/v0/b/buffuppolitics.appspot.com/o/images%2Fae3ecb82-3118-5ac7-afe1-8fe451087eeb?alt=media&token=fdbe360a-3c09-44af-a4ed-6a01207afea8'
  }
]

class PresetBuffCreateView extends React.Component {

  handleUpdateThenCreate = (crudUpdateThenCreate, gameId, oldPresetId, values) => {
    return crudUpdateThenCreate(
      'preset_buffs',
      oldPresetId,
      { is_enabled: false, _basePath: values._basePath },
      {},
      values,
      `/games/${gameId}/show/1`
    )
  }

  handleSave = inputValues => {
    const values = cloneDeep(inputValues)
    const { save, gameId, presetId, crudUpdateThenCreate } = this.props

    //If the author's name is null there's no author
    const authorName = get(values, 'author.name')
    if (!isString(authorName) || authorName.length === 0) {
      delete values.author
    }

    //Add the right labels to each choice
    const choices = get(values, 'info.choices')
    const labels = 'ABC'.split('')
    let stats = generateStats(choices.length, 0)

    for (let i = 0; i < choices.length; i++) {
      choices[i]['label'] = labels[i % labels.length]
      stats[`${i}`] = choices[i].stats || 0
      delete choices[i].stats
    }

    for (let i = 0; i < choices.length; i++) {
      choices[i]['label'] = labels[i % labels.length]
    }

    values['stats'] = stats

    if (crudUpdateThenCreate && isString(gameId) && isString(presetId)) {
      this.handleUpdateThenCreate(crudUpdateThenCreate, gameId, presetId, values)
    } else {
      save(values, `/games/${gameId}/show/1`)
    }
  }

  componentWillMount() {
    const stats = get(this.props, 'record.stats')
    if (!isObject(stats)) {
      return
    }
    Object.keys(stats).forEach(key => {
      const i = parseInt(key, 10)
      if (isNaN(i)) {
        return
      }
      set(this.props, `record.info.choices[${i}].stats`, stats[key])
    })
  }

  changeIt = (_, id) => {
    console.debug(id)
    console.debug('PROPS', this.props)
    console.debug('STATE', this.state)

    const { change } = this.props

    const avatar = find(avatars, (av) => av.id === id)
    console.debug('IMAGE', avatar)

    change(REDUX_FORM_NAME, 'author.image', avatar.url)
  }

  handleBla = ({ is_enabled }) => {
    console.debug('isss', is_enabled)
  }

  render() {
    const { gameId, presetId, defaultAuthorName, defaultAuthorImage, ...rest } = this.props

    return (
      <SimpleForm
        {...rest}
        save={this.handleSave}
        toolbar={<PresetBuffCreateToolbar/>}
        defaultValue={{
          _basePath: new FirebasePath([new FirebasePathComponent('games', gameId)])
        }}
        onChange={this.handleBla}
      >
        {/*<TextInput*/}
        {/*  source="author.name"*/}
        {/*  label="Author Name"*/}
        {/*  validate={[minLength(1)]}*/}
        {/*  defaultValue={defaultAuthorName}*/}
        {/*/>*/}

        <SelectInput
          source="author.name"
          label="Author Name"
          onChange={this.changeIt.bind(this)}
          choices={avatars}
          optionText="name"
          optionValue="name"
          defaultValue="Pre Match"
          validate={[required()]}
        />

        <ImageInput
          source="author.image"
          label="Author Image"
          defaultValue={defaultAuthorImage}
        >
          <ImageField source="src"/>
        </ImageInput>

        <Divider/>
        <BooleanInput
          source="is_enabled"
          defaultValue={true}
          validate={[required()]}
        />
        <LocalizableTextInput
          source="info.question"
          label="Question"
          validate={[required(), minLength(1), maxLength(46)]}
        />
        <NumberInput
          source="info.countdown"
          step={1}
          label="Countdown"
          defaultValue={7}
          validate={[required(), minValue(5), maxValue(60)]}
        />
        <ArrayInput
          fullWidth
          source="info.choices"
          label="Choices (At least two)"
          validate={[required(), minLength(2), maxLength(3)]}
          defaultValue={[
            { text: { en_US: 'Yes' } },
            { text: { en_US: 'No' } }
          ]}
        >
          <SimpleFormIterator
            disableAdd={(fields) => fields.length > 2}
            disableRemove={(fields) => fields.length < 3}
            defaultFields={{ points_value: 1, stats: 0 }}
          >
            <LocalizableTextInput
              source="text"
              label="Text"
              validate={[required(), minLength(1), maxLength(20)]}
            />
            <NumberInput
              source="points_value"
              defaultValue={1}
              validate={[required(), minValue(1)]}
            />
            <ImageInput
              source="image"
              label="Image"
            >
              <ImageField source="src"/>
            </ImageInput>
            <NumberInput
              source="stats"
              label="Preloaded Answers"
              defaultValue={0}
              validate={[required(), minValue(0)]}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <Divider/>
        <SelectInput
          source="game_state"
          label="Can be sent on"
          choices={[
            { id: 'Pre Match', name: 'Pre Match' },
            { id: 'Ongoing', name: 'Ongoing' },
            { id: 'Post Match', name: 'Post Match' }
          ]}
          defaultValue="Pre Match"
          validate={[required()]}
        />
        <BooleanInput
          source="can_be_sent_by_users"
          defaultValue={true}
          validate={[required()]}
        />
      </SimpleForm>
    )
  }

}

PresetBuffCreateView.defaultProps = {
  gameId: ''
}

PresetBuffCreateView.propTypes = {
  gameId: PropTypes.string.isRequired,
  presetId: PropTypes.string,
  crudUpdateThenCreate: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  defaultAuthorName: PropTypes.string,
  defaultAuthorImage: PropTypes.string
}

const mapStateToProps = (state) => {
  console.debug('STATE', state)
  return {}
}

const PresetBuffCreateThenUpdateView = connect(
  mapStateToProps,
  { crudUpdateThenCreate, change }
)(PresetBuffCreateView)

export const PresetBuffCreate = ({
                                   gameId,
                                   ...rest
                                 }) => {
  const disableOnCreate = get(rest, 'location.state.disableOnCreate')
  const defaultAuthorName = get(rest, 'location.state.defaultAuthorName')
  const defaultAuthorImage = get(rest, 'location.state.defaultAuthorImage')
  return (
    <Create {...rest} title="New Buff">
      <PresetBuffCreateThenUpdateView
        gameId={gameId}
        presetId={disableOnCreate}
        defaultAuthorName={defaultAuthorName}
        defaultAuthorImage={defaultAuthorImage}
      />
    </Create>
  )
}
