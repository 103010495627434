import React from 'react'
import PropTypes from 'prop-types'
import IconAdd from  '@material-ui/icons/Add'
import { Button } from 'ra-ui-materialui'
import { Link } from 'react-router-dom'

const omitId = ({ id, ...rest }) => rest

const CreatePresetButton = ({
    gameId = "",
    disableCurrentRecord = false,
    defaultAuthorName = undefined,
    defaultAuthorImage = undefined,
    record = undefined,
    icon = <IconAdd />,
    label = "New",
    ...rest
}) => {
    let to = undefined
    let isEnabled = true
    if (record === undefined || record.id === gameId) {
        to = {
            pathname: `/games/${gameId}/preset_buffs/create`,
            state: {
                defaultAuthorName: defaultAuthorName,
                defaultAuthorImage: defaultAuthorImage
            }
        }
    } else {
        to = {
            pathname: `/games/${gameId}/preset_buffs/create`,
            state: {
                defaultAuthorName: defaultAuthorName,
                defaultAuthorImage: defaultAuthorImage,
                disableOnCreate: disableCurrentRecord ? record.id : undefined,
                record: omitId(record)
            }
        }
        isEnabled = record.is_enabled
    }
    return (
        <Button
            disabled={!isEnabled}
            component={Link}
            to={to}
            onClick={(e) => e.stopPropagation()}
            label={label}
            {...rest}
        >
            {icon}
        </Button>
    )
}

CreatePresetButton.propTypes = {
    gameId: PropTypes.string.isRequired,
    disableCurrentRecord: PropTypes.bool,
    defaultAuthorName: PropTypes.string,
    defaultAuthorImage: PropTypes.string,
    record: PropTypes.object,
    color: PropTypes.string,
    icon: PropTypes.element
}

export default CreatePresetButton
