import React, {
    Children,
    cloneElement,
    Component,
    isValidElement,
} from 'react'
import { FormInput } from 'react-admin'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import { withStyles, createStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import classNames from 'classnames'

const styles = theme =>
    createStyles({
        root: {
            padding: 0,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'wrap'
        },
        addItem: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'block',
            listStyleType: 'none',
            borderRadius: '2%',
            [theme.breakpoints.down('xs')]: { display: 'block' },
            margin: 0,
            border: 'none',
            flex: '1 100%'
        },
        line: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 16,
            marginRight: 8,
            display: 'block',
            listStyleType: 'none',
            borderRadius: '2%',
            border: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' }
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        leftIcon: {
            marginLeft: -8,
            marginRight: theme.spacing.unit,
        },
    })

export class SimpleFormIterator extends Component {

    constructor(props) {
        super(props)
        // we need a unique id for each field for a proper enter/exit animation
        // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
        // so we keep an internal map between the field position and an autoincrement id
        this.nextId = props.fields.length
            ? props.fields.length
            : props.defaultValue
            ? props.defaultValue.length
            : 0

        // We check whether we have a defaultValue (which must be an array) before checking
        // the fields prop which will always be empty for a new record.
        // Without it, our ids wouldn't match the default value and we would get key warnings
        // on the CssTransition element inside our render method
        this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : []
    }

    removeField = index => () => {
        const { fields } = this.props
        this.ids.splice(index, 1)
        fields.remove(index)
    }

    addField = () => {
        const { fields, defaultFields } = this.props
        this.ids.push(this.nextId++)
        fields.push(defaultFields || {})
    }

    render() {
        const {
            basePath,
            classes = {},
            children,
            fields,
            record,
            resource,
            source,
            disableAdd,
            disableRemove
        } = this.props
        const records = get(record, source)
        return fields ? (
            <ul className={classes.root}>
                {fields.map((member, index) => (
                    <li className={classes.line} key={index}>
                        {
                            (disableRemove === undefined || !disableRemove(fields)) &&
                            (
                                <span className={classes.action}>
                                    <Button
                                        className={classNames(
                                            'button-remove',
                                            `button-remove-${source}-${index}`
                                        )}
                                        size="small"
                                        onClick={this.removeField(index)}
                                    >
                                        <CloseIcon className={classes.leftIcon} />Remove
                                    </Button>
                                </span>
                            )
                        }
                        <section className={classes.form}>
                            {
                                Children.map(children, (input, index2) => {
                                    if (!isValidElement(input)) {
                                        return null
                                    }

                                    return (
                                        <FormInput
                                            basePath={
                                                input.props.basePath ||
                                                basePath
                                            }
                                            input={cloneElement(input, {
                                                source: input.props.source
                                                    ? `${member}.${input.props.source}`
                                                    : member,
                                                index: input.props.source
                                                    ? undefined
                                                    : index2,
                                                label:
                                                    input.props.label
                                                    || input.props.source,
                                            })}
                                            record={ (records && records[index]) || {} }
                                            resource={resource}
                                        />
                                    )
                                })
                            }
                        </section>
                    </li>
                ))}
                {
                    (disableAdd === undefined || !disableAdd(fields)) &&
                    (
                        <li className={classes.addItem}>
                            <span className={classes.action}>
                                <Button
                                    className={classNames(
                                        'button-add',
                                        `button-add-${source}`
                                    )}
                                    size="small"
                                    onClick={this.addField}
                                >
                                    <AddIcon className={classes.leftIcon} />
                                    Add Choice
                                </Button>
                            </span>
                        </li>
                    )
                }
            </ul>
        ) : null
    }

}

SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    defaultFields: PropTypes.object,
    fields: PropTypes.object,
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    disableAdd: PropTypes.func,
    disableRemove: PropTypes.func,
}

export default compose(
    withStyles(styles)
)(SimpleFormIterator)