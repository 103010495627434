import React from 'react'

import {
  List,
  Create,
  Edit,
  Show,

  Datagrid,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,

  UrlField,
  DateField,
  TextField,
  NumberField,
  FunctionField,
  ChipField,
  ReferenceManyField,

  TextInput,
  BooleanInput,
  SelectInput,
  DateTimeInput,
  NumberInput,

  required,
  minLength,
  minValue,
  maxValue,
  regex, ShowButton
} from 'react-admin'
import get from 'lodash/get'
import icon from '@material-ui/icons/LiveTv'
import IconCheck from '@material-ui/icons/ThumbUp'
import IconClose from '@material-ui/icons/ThumbDown'
import IconUpDown from '@material-ui/icons/ThumbsUpDown'
import IconDuplicate from '@material-ui/icons/ContentCopy'
import {
  Switch,
  Typography
} from '@material-ui/core'
import { FirebasePath, FirebasePathComponent } from '../firebase/model'
import ResolvePresetButton from '../components/ResolvePresetButton'
import SendPresetButton from '../components/SendPresetButton'
import CreatePresetButton from '../components/CreatePresetButton'
import LocalizableTextField from '../components/LocalizableTextField'
import LocalizableTextInput from '../components/LocalizableTextInput'
import ImageInput from '../components/ImageInput'
import ImageField from '../components/ImageField'
import BooleanField from '../components/BooleanField'
import GameStats from './GameStats'

export const GameIcon = icon

const presetRowStyleShowDisabled = (record, index, defaultStyle = {}) => {
  return {
    ...defaultStyle,
    opacity: record.is_enabled === true ? 1 : 0.3
  }
}

const presetRowStyle = (record, index, defaultStyle = {}) => {
  return {
    ...defaultStyle,
    display: record.is_enabled === true ? 'auto' : 'none'
  }
}

const GameTitle = ({ record }) => {
  return <span>{record ? record.internal_name : 'Broadcast'}</span>
}

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i

const nameRule = [required(), minLength(1)]
const urlRule = [required(), regex(urlRegex, 'Invalid URL')]
//TODO: add the date validation to the interval values

const GameCreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      label="Save"
      submitOnEnter={true}
      disabled={props.pristine || props.invalid}
    />
  </Toolbar>
)

export const GameList = props => (
  <List {...props} title="Broadcasts" pagination={null}>
    <Datagrid expand={<GameStats/>}>
      <TextField source="internal_name" label="Internal Name"/>
      <LocalizableTextField source="name" label="Name" hideLabel/>
      <FunctionField label="Teams" render={r => `${r.home_team.name} VS ${r.away_team.name}`}/>
      <BooleanField source="is_enabled" label="Enabled"/>
      <ShowButton/>
    </Datagrid>
  </List>
)

const GameEditView = (props) => (
  <TabbedForm toolbar={<GameCreateToolbar/>} redirect="list">
    <FormTab label="Summary">
      <BooleanInput source="is_enabled" defaultValue={true}/>
      <TextInput
        source="internal_name"
        label="Internal Name"
        validate={nameRule}
        className={'fullwidth'}
      />
      <LocalizableTextInput
        source="name"
        label="Name"
        validate={[required()]}
      />
      <LocalizableTextInput
        multiline
        source="description"
        label="Description"
        validate={[required()]}
      />
      <TextInput
        source="stream"
        type="url"
        validate={urlRule}
        className={'fullwidth'}
      />
      <ImageInput
        source="image"
        label="Image"
        validate={[required()]}
      >
        <ImageField source="src"/>
      </ImageInput>
      <ImageInput
        source="channel_image"
        label="Channel Image"
        validate={[required()]}
      >
        <ImageField source="src"/>
      </ImageInput>
      <SelectInput
        source="game_state"
        label="Current Game State"
        choices={[
          { id: 'Pre Match', name: 'Pre Match' },
          { id: 'Ongoing', name: 'Ongoing' },
          { id: 'Post Match', name: 'Post Match' }
        ]}
        defaultValue="Pre Match"
        validate={[required()]}
      />
      <DateTimeInput
        source="open_date"
        label="Starts At"
        defaultValue={new Date()}
        validate={[required()]}
      />
      <DateTimeInput
        source="close_date"
        label="Ends At"
        defaultValue={new Date()}
        validate={[required()]}
      />
      <NumberInput
        source="default_countdown"
        step={1}
        label="Countdown for User-Created Buffs"
        defaultValue={7}
        validate={[required(), minValue(5), maxValue(60)]}
      />
    </FormTab>
    <FormTab label="Home Team">
      <TextInput
        source="home_team.name"
        label="Name"
        validate={nameRule}
      />
      <ImageInput
        source="home_team.image"
        label="Image"
        validate={[required()]}
      >
        <ImageField source="src"/>
      </ImageInput>
    </FormTab>
    <FormTab label="Away Team">
      <TextInput
        source="away_team.name"
        label="Name"
        validate={nameRule}
      />
      <ImageInput
        source="away_team.image"
        label="Image"
        validate={[required()]}
      >
        <ImageField source="src"/>
      </ImageInput>
    </FormTab>
    <FormTab label="Default Author">
      <TextInput
        source="author.name"
        label="Name"
        defaultValue={undefined}
        validate={[minLength(1)]}
      />
      <ImageInput
        source="author.image"
        label="Image"
        defaultValue={undefined}
      >
        <ImageField source="src"/>
      </ImageInput>
    </FormTab>
  </TabbedForm>
)

export const GameCreate = props => (
  <Create title="New Broadcast" {...props}>
    {GameEditView()}
  </Create>
)

export const GameEdit = props => (
  <Edit title={<GameTitle/>} {...props}>
    {GameEditView()}
  </Edit>
)

let displayHidden = false

class PresetBuffsTab extends React.Component {

  constructor(props) {
    super(props)
    this.state = { displayHidden: displayHidden }
  }

  setDisplayHidden(v) {
    this.setState({ displayHidden: v })
    displayHidden = v
  }

  render() {
    const { id: gameId, ...rest } = this.props
    const authorName = get(this.props.record, 'author.name')
    const authorImage = get(this.props.record, 'author.image')
    return (
      <Tab label="Preset Buffs" path="preset_buffs" {...rest}>
        <span style={{ display: 'flex' }}>
          <span style={{ flex: 1 }}/>
          <CreatePresetButton
            gameId={gameId}
            color="primary"
            defaultAuthorName={authorName}
            defaultAuthorImage={authorImage}
          />
        </span>

        <ReferenceManyField
          reference="preset_buffs"
          target={{ _basePath: new FirebasePath([new FirebasePathComponent('games', gameId)]) }}
          addLabel={false}
        >
          <Datagrid
            rowStyle={this.state.displayHidden ? presetRowStyleShowDisabled : presetRowStyle}
            rowClick={id => `/games/${gameId}/preset_buffs/${id}`}
          >
            <TextField source="author.name" label="Author" defaultValue="N/A"/>
            <LocalizableTextField source="info.question" label="Question" defaultValue="N/A" hideLabel/>
            <BooleanField source="can_be_sent_by_users" label="User Enabled"/>
            <BooleanField source="resolved" label="Resolved?" defaultValue={false}/>
            <ChipField source="game_state" label="Enabled During" defaultValue="Entire Broadcast"/>
            <ResolvePresetButton gameId={gameId} answerIndex={0} icon={<IconCheck/>} label="Resolve (1st)"/>
            <ResolvePresetButton gameId={gameId} answerIndex={1} icon={<IconClose/>} label="Resolve (2nd)"/>
            <ResolvePresetButton gameId={gameId} answerIndex={2} icon={<IconUpDown/>} label="Resolve (3rd)"/>
            <SendPresetButton gameId={gameId} color="primary" label="Send"/>
            <CreatePresetButton
              gameId={gameId}
              disableCurrentRecord={true}
              icon={<IconDuplicate/>}
              color="secondary"
              label=""
              defaultAuthorName={get(this.props, 'author.name')}
              defaultAuthorImage={get(this.props, 'author.image')}
            />
          </Datagrid>
        </ReferenceManyField>

        <span style={{ display: 'flex' }}>
          <Typography
            component="span"
            variant={'body1'}
          >
              <Switch checked={this.state.displayHidden} onChange={(_, v) => this.setDisplayHidden(v)}/>Include Disabled
          </Typography>
          <span style={{ flex: 1 }}/>
          <CreatePresetButton
            gameId={gameId}
            color="primary"
            defaultAuthorName={authorName}
            defaultAuthorImage={authorImage}
          />
          </span>
      </Tab>
    )
  }

}

const GameView = (props) => (
  <TabbedShowLayout {...props}>
    <Tab label="Details">
      <TextField
        source="id"
        label="ID"
      />
      <FunctionField label="Teams" render={r => `${r.home_team.name} VS ${r.away_team.name}`}/>
      <TextField
        source="default_countdown"
        label="Countdown for User-Created Buffs"
      />
      <TextField
        source="internal_name"
        label="Internal Name (users won't see this)"
      />
      <LocalizableTextField
        source="name"
        label="Name"
      />
      <LocalizableTextField
        source="description"
        label="Description"
      />
      <UrlField
        target="_blank"
        source="stream"
      />
      <ImageField
        source="image"
        title="Image"
        label="Image"
      />
      <ImageField
        source="channel_image"
        title="Channel Image"
        label="Channel Image"
      />
      <ChipField
        source="game_state"
        label="Currently At"
      />
      <DateField
        source="open_date"
        label="Starts At"
        showTime
      />
      <DateField
        source="close_date"
        label="Ends At"
        showTime
      />
    </Tab>
    <PresetBuffsTab {...props}/>
    <Tab label="Sent Buffs" path="sent_buffs">
      <ReferenceManyField
        sort={{ field: 'created_at', order: 'DESC' }}
        reference="sent_buffs"
        target={{ _basePath: new FirebasePath([new FirebasePathComponent('games', props.id)]) }}
        addLabel={false}
      >
        <Datagrid>
          <TextField source="author.name" label="Author" defaultValue="N/A"/>
          <LocalizableTextField source="info.question" label="Question" defaultValue="N/A" hideLabel/>
          <DateField source="created_at" label="Created At" showTime/>
          <BooleanField source="resolved" label="Resolved?"/>
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Rankings" path="leaderboard">
      <ReferenceManyField
        sort={{ field: 'points', order: 'DESC' }}
        reference="leaderboard"
        target={{ _basePath: new FirebasePath([new FirebasePathComponent('games', props.id)]) }}
        addLabel={false}
      >
        <Datagrid>
          <TextField source="info.name" label="User Name" sortable={false}/>
          <NumberField source="points" label="Trophies" sortable={false}/>
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  </TabbedShowLayout>
)

export const GameShow = ({ hasCreate, hasShow, hasEdit, hasList, basePath, refreshView, ...rest }) => {
  return (
    <Show
      title={<GameTitle/>}
      basePath={basePath}
      hasCreate={hasCreate}
      hasShow={hasShow}
      hasEdit={hasEdit}
      hasList={hasList}
      {...rest}
    >
      <GameView {...rest} />
    </Show>
  )
}
