import React from 'react'
import {
    Create,
    List,
    Show,

    Datagrid,
    SimpleForm,
    TabbedShowLayout,
    Tab,
    Toolbar,
    Filter,
    SaveButton,

    TextInput,

    ReferenceManyField,
    TextField,
    FunctionField,
    DateField,

    required,
    minLength
} from 'react-admin'
import { FirebasePath, FirebasePathComponent } from '../firebase/model'
import icon from '@material-ui/icons/People'
import OpenInNew from '@material-ui/icons/OpenInNew'
import BooleanFunctionField from '../components/BooleanFunctionField'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import LocalizableTextField from '../components/LocalizableTextField'
import { getLocalizedString } from '../utils/localized'
import NumberField from '../components/NumberField'
import { withStyles, createStyles } from '@material-ui/core/styles'
import ImageInput from '../components/ImageInput'
import ImageField from '../components/ImageField'

const nameRule = [required(), minLength(1)]

export const UserIcon = icon

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)

const UserCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Save"
            submitOnEnter={true}
            disabled={props.pristine || props.invalid}
        />
    </Toolbar>
)

export const UserCreate = props => (
    <Create {...props} title="New User">
        <SimpleForm toolbar={ <UserCreateToolbar /> }>
            <TextInput
                source="info.name"
                label="Name"
                validate={nameRule}
            />
            <ImageInput
                source="info.image"
                label="Profile Image"
                accept={["image/jpg", "image/png"]}
                validate={[required()]}
            >
                <ImageField source="src" />
            </ImageInput>
        </SimpleForm>
    </Create>
)

const listStyles = theme =>
    createStyles({
        number: {
            width: '1em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    })

const UserListView = ({classes, ...props}) => (
    <List
        {...props}
        title="Users"
        filters={<UserFilter />}
        bulkActionButtons={false}
        pagination={null}
    >
        <Datagrid rowClick="show">
            <TextField
                source="info.name"
                label="Name"
            />
            <FunctionField
                label="Facebook Friends"
                render={r => `${(r.facebook_friend_ids || []).length}`}
                cellClassName={classes.number}
            />
            <NumberField
                source="points"
                label="Trophies"
                defaultValue={0}
                cellClassName={classes.number}
            />
        </Datagrid>
    </List>
)

export const UserList = withStyles(listStyles)(UserListView)

const OpenInNewButton = ({
    label = "Open",
    color = "secondary",
    icon = <OpenInNew />,
    record = {},
    source = "id",
    reference
}) =>  {
    return (
        <Button
            color={color}
            label={label}
            component={Link}
            to={`/${reference}/${get(record, source)}/show`}
        >
            {icon}
        </Button>
    )
}

export const UserShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <ImageField source="info.image" label="Profile Image" />
                <TextField source="info.name" label="Name" />
                <TextField source="facebook_id" label="Facebook ID" defaultValue="N/A" />
            </Tab>
            <Tab label="Answers">
            <ReferenceManyField
                reference="answered_buffs"
                target={{_basePath: new FirebasePath([new FirebasePathComponent("users", props.id)])}}
                addLabel={false}
                sort={{ field: 'created_at', order: 'DESC' }}
            >
            <Datagrid>
                <OpenInNewButton source="game_id" reference="games" label="Open Game" />
                <LocalizableTextField source="info.question" label="Question" defaultValue="N/A" hideLabel />
                <FunctionField render={ r => `${getLocalizedString(get(r, `info.choices[${r.answer_index}].text`)) || "N/A"}`} label="Answer" />
                <BooleanFunctionField render={ r => !isNil(r.answered_right) } label="Resolved?" />
                <FunctionField render={r => `${get(r, `points`) || 0}`} label="# of Trophies Won" />
                <DateField source="created_at" label="Created At" showTime />
            </Datagrid>
        </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)