import React from 'react'
import compose from 'recompose/compose'
import { addField, FieldTitle } from 'ra-core'
import FormControl from '@material-ui/core/FormControl'
import { withStyles, createStyles } from '@material-ui/core/styles'
import localeEmoji from 'locale-emoji'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import set from 'lodash/set'
import MuiTextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import classnames from 'classnames'
import { DefaultLocales } from '../utils/localized'

const styles = theme =>
    createStyles({
        root: {
            padding: 0,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'wrap'
        },
        addItem: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            display: 'block',
            listStyleType: 'none',
            borderRadius: '2%',
            [theme.breakpoints.down('xs')]: { display: 'block' },
            margin: 0,
            border: 'none',
            flex: '1 100%'
        },
        line: {
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 16,
            marginRight: 8,
            display: 'block',
            listStyleType: 'none',
            borderRadius: '2%',
            border: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' }
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        leftIcon: {
            marginLeft: -8,
            marginRight: theme.spacing.unit,
        },
        input: {
            minWidth: theme.spacing.unit * 10,
        }
    })


class LocalizableTextInput extends React.Component {

    static getDerivedStateFromProps(props, state) {
        const extractKeysValues = (value) => {
            const keys = sortBy(Object.keys(value) || [], (k) => DefaultLocales.indexOf(k))
            const values = keys.map(key => value[key]) || []
            return {keys, values}
        }

        let { keys, values } = extractKeysValues(props.input.value)

        if (props.defaultValue && (keys.length === 0 || values.length === 0)) {
            const { keys: defaultKeys, values: defaultValues } = extractKeysValues(props.defaultValue)
            keys = defaultKeys
            values = defaultValues
        }

        return { keys: keys, values: values }
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    handleBlur = eventOrValue => {
        this.props.onBlur(eventOrValue)
        this.props.input.onBlur(eventOrValue)
    }

    handleFocus = event => {
        this.props.onFocus(event)
        this.props.input.onFocus(event)
    }

    onChangeKey = index => event => {
        const newKey = event.target.value
        let keys = [...get(this.state, "keys")]
        let newValue = { ...this.props.input.value }
        const oldKey = keys[index]
        keys[index] = newKey
        this.setState({ keys: keys })
        set(newValue, newKey, get(newValue, oldKey))
        delete newValue[oldKey]
        this.props.input.onChange(newValue)
    }

    onChangeValue = index => event => {
        const { keys } = this.state
        const value = event.target.value
        const key = keys[index]
        // let values = [...get(this.state, "values")]
        // values[index] = value
        // this.setState({ values: values })

        let newValue = { ...this.props.input.value }
        set(newValue, key, value)
        this.props.input.onChange(newValue)
    }

    renderMenuItemOption(choice) {
        const { optionText, translate, translateChoice } = this.props
        if (React.isValidElement(optionText))
            return React.cloneElement(optionText, {
                record: choice,
            })
        const choiceName =
            typeof optionText === 'function'
                ? optionText(choice)
                : get(choice, optionText)
        return translateChoice
            ? translate(choiceName, { _: choiceName })
            : choiceName
    }

    renderMenuItem(choice) {
        const { keys } = this.state
        const key = get(choice, "id")
        const name = get(choice, "name")
        const disabled = keys.includes(key)
        return (
            <MenuItem
                key={key}
                value={key}
                disabled={disabled}
            >
                {name}
            </MenuItem>
        )
    }

    removeField = index => () => {
        let keys = [...get(this.state, "keys")]
        let values = [...get(this.state, "values")]

        keys.splice(index, 1)
        values.splice(index, 1)

        this.setState({
            keys: keys,
            // values: values
         })
        this.updateInput(keys, values)
    }

    addField() {
        const nextKey = this.nextKey()
        if (!nextKey) { return }

        let keys = [...get(this.state, "keys")]
        let values = [...get(this.state, "values")]

        keys.push(nextKey)
        values.push("")

        this.setState({
            keys: keys,
            // values: values
        })
        this.updateInput(keys, values)
    }

    updateInput(keys, values) {
        let newValue = { ...this.props.input.value }
        Object.keys(newValue).forEach(key => {
            if (!keys.includes(key)) {
                delete newValue[key]
            }
        })
        keys.forEach((key, index) => set(newValue, key, values[index]))
        this.props.input.onChange(newValue)
    }

    nextKey() {
        const keys = get(this.state, "keys")
        if (keys) {
            return DefaultLocales.find(e => !keys.includes(e))
        }
        return undefined
    }

    render() {
        const {
            classes = {},
            source,
            label,
            isRequired,
            resource,
            multiline,
            input,
            meta
        } = this.props

        if (typeof meta === 'undefined') {
            throw new Error(
                "The LocalizableTextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
            )
        }

        const choices = DefaultLocales.map(id => ({id: id, name: localeEmoji(id)}))
        const { keys } = this.state

        return (
            <FormControl
                fullWidth
            >
                <InputLabel htmlFor={source} shrink>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </InputLabel>
                <ul className={classes.root}>
                    {
                        keys.map((key, index) => (
                            <li className={classes.line} key={index}>
                                {
                                    keys.length > 1 &&
                                    (
                                        <span className={classes.action}>
                                            <Button
                                                className={classnames(
                                                    'button-remove',
                                                    `button-remove-${input.name}-${index}`
                                                )}
                                                size="small"
                                                onClick={this.removeField(index).bind(this)}
                                            >
                                                <CloseIcon className={classes.leftIcon} />Remove
                                            </Button>
                                        </span>
                                    )
                                }
                                <section className={classes.form}>
                                    <MuiTextField
                                        select
                                        className={classes.input}
                                        label={
                                            <FieldTitle
                                                label="Language"
                                            />
                                        }
                                        onChange={this.onChangeKey(index).bind(this)}
                                        value={key}
                                    >
                                        { choices.map(this.renderMenuItem.bind(this)) }
                                    </MuiTextField>
                                    <MuiTextField
                                        multiline={multiline}
                                        fullWidth={multiline}
                                        label={
                                            <FieldTitle
                                                label="Text"
                                            />
                                        }
                                        value={get(input, `value.${key}`)}
                                        onChange={this.onChangeValue(index).bind(this)}
                                    />
                                </section>
                            </li>
                        ))
                    }
                    {
                        this.nextKey() !== undefined &&
                        (
                            <li className={classes.addItem}>
                                <span className={classes.action}>
                                    <Button
                                        className={classnames(
                                            'button-add',
                                            `button-add-${input.name}`
                                        )}
                                        size="small"
                                        onClick={this.addField.bind(this)}
                                    >
                                        <AddIcon className={classes.leftIcon} />
                                        Add
                                    </Button>
                                </span>
                            </li>
                        )
                    }
                </ul>
            </FormControl>
        )
    }
}

export default compose(
    addField,
    withStyles(styles)
)(LocalizableTextInput)