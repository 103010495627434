import React from 'react'
import PropTypes from 'prop-types'
import {crudGetList as crudGetListAction } from 'react-admin'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SearchInput } from 'ra-ui-materialui'
import { connect } from 'react-redux'
import Img from 'react-image'
import moment from 'moment'
import _ from 'lodash'
import uuid from 'uuid/v5'

const styles = createStyles({
    image: {
        height: "100%",
        width: "100%",
        "object-fit": "contain"
    },
    imageContainer: {
        height: "6rem",
        width: "6rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ddd",
        borderRadius: "0.5rem",
        overflow: "hidden"
    },
    row: {
        height: "8rem"
    }
})

class ImageListItemView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        const item = this.props.item
        Promise.all([
            item.getURL(),
            item.getMetadata ? item.getMetadata() : Promise.resolve({})
        ]).then(([url, metadata]) => {
            this.setState({url: url, metadata: metadata})
        })
        .catch(error => {
            //TODO: handle error
        })
    }

    componentWillUnmount() {
        this.setState({})
    }

    render() {
        const { onClick, classes, height, item } = this.props
        const {url, metadata} = this.state

        const { title, description } = (() => {
            if (!metadata) { return {title: item.name} }

            const originalName = _.get(metadata, "customMetadata.original_name")
            const updatedDate = metadata.updated ? moment(metadata.updated) : undefined

            return {
                title: originalName || item.name,
                description: updatedDate ? `Last updated ${updatedDate.fromNow()}` : undefined
            }
        })()

        const hasContent = url !== undefined && metadata !== undefined

        return (
            <ListItem
                style={{
                    height: `${height}rem`
                }}
                divider
                button
                onClick={() => {
                    if (hasContent) {
                        onClick(url)
                    }
                }}
            >
            <div className={classes.imageContainer}>
            { hasContent
                ? (<Img alt={title} src={url} title={title} className={classes.image} />)
                : <CircularProgress size={25} thickness={2} />
            }
            </div>
            <ListItemText primary={title} secondary={description} />
            </ListItem>
        )
    }

}

const ImageListItem = withStyles(styles)(ImageListItemView)

class DisconnectedImageResourcePicker extends React.Component {

    componentDidMount() {
        this.updateData()
    }

    updateData(query) {
        this.props.crudGetListAction("images", undefined, undefined, query ? { q: query } : undefined)
    }

    loadNextPage(nextPageToken) {
        this.props.crudGetListAction("images", {
            previousData: this.props.ids.map(id => this.props.data[id]),
            nextPageToken: nextPageToken
        })
    }

    onSearchChange = event => {
        const value = event.target.value
        this.updateData(_.deburr(value.toLowerCase()))
    }

    onScroll = (e) => {
        const target = e.target
        if (!target) {
            return
        }

        const isLoading = _.get(this, "props.isLoading") || false
        const nextPageToken = _.get(this, "props.nextPageToken")

        if (isLoading || !nextPageToken) {
            return
        }

        const count = _.get(this, "props.ids.length") || 0
        const rowHeight = _.get(this, "props.rowHeight")
        const boundsHeight = parseFloat(_.get(window.getComputedStyle(target), "height")) || 0
        const fontSize = parseFloat(_.get(window.getComputedStyle(document.querySelector("html")), "font-size") || 1)
        const rowHeightPx = rowHeight * fontSize
        const maxY = count * rowHeightPx - boundsHeight
        const y = _.get(target, "scrollTop") || 0

        if (y > maxY - rowHeightPx * 2) {
            this.loadNextPage(nextPageToken)
        }
    }

    render() {
        const { ids, onSelect, close, rowHeight, isLoading } = this.props
        return (
            <div
                onScroll={this.onScroll.bind(this)}
                style={{
                    margin: 0,
                    padding: 0,
                    height: "40rem",
                    overflowX: 'hidden',
                    overflowY: 'scroll'
                }}
            >
                <SearchInput
                    style={{
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        boxSizing: "border-box"
                    }}
                    fullWidth
                    onChange={
                        _.debounce(this.onSearchChange.bind(this), 200, { leading:false, trailing:true })
                    }
                />
                <List>
                {
                    ids.map(id => this.props.data[id]).map(item => (
                        <ImageListItem
                            height={rowHeight}
                            key={uuid(item.id + (item.getMetadata !== undefined), "37e98d9d-11db-45e7-851d-127a0f610093")}
                            item={item}
                            onClick={(url) => {
                                onSelect(url)
                                close()
                            }}
                        />
                    ))
                }
                { isLoading && (
                    <ListItem><CircularProgress size={25} thickness={2} style={{ margin: "auto" }}/></ListItem>
                )}
                </List>
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    const resourceState = state.admin.resources["images"]
    const ids = _.get(resourceState, "list.ids")
    const data = resourceState.data
    const lastId = _.last(ids)
    const lastElement = lastId ? _.get(data, lastId) : undefined

    return {
        total: resourceState.list.total,
        ids: ids,
        nextPageToken: _.get(lastElement, "nextPageToken"),
        data: data,
        isLoading: state.admin.loading > 0,
        version: state.admin.ui.viewVersion
    }
}

const ImageResourcePicker = connect(
    mapStateToProps,
    {
        crudGetListAction
    }
)(DisconnectedImageResourcePicker)

export default ImageResourcePicker

ImageListItem.propTypes = {
    height: PropTypes.number
}

ImageResourcePicker.defaultProps = {
    rowHeight: 8,
}

ImageResourcePicker.propTypes = {
    rowHeight: PropTypes.number
}
