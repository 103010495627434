/**
 * @param {any} objc
 * @param {Function} asyncFn
 * @returns {Promise<any>}
 */
export default (obj, asyncFn) => {
    const keys = Object.keys(obj)
    const promises = keys.map(k => {
      return asyncFn(obj[k]).then(newValue => {
        return {key: k, value: newValue}
      })
    })
    return Promise.all(promises).then(values => {
      const newObj = Object(obj)
      values.forEach(v => {
        newObj[v.key] = v.value
      })
      return newObj
    })
  }