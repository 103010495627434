import React from 'react'
import PropTypes from 'prop-types'
import shouldUpdate from 'recompose/shouldUpdate'
import IconSend from  '@material-ui/icons/Send'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { publishPreset } from '../firebase/actions'

class SendPresetButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { isLoading: false }
    }

    render() {
        const { gameId, record, icon, ...rest } = this.props
        const { isLoading } = this.state

        return (
            <Button
                disabled={isLoading || !record.is_enabled}
                label="Send"
                onClick={e => this.handleClick(e, gameId, record.id) }
                {...rest}
            >
            { isLoading ? (<CircularProgress size={25} thickness={2} />) : (icon) }
            </Button>
        )
    }

    handleClick(e, gameId, presetId) {
        e.stopPropagation()

        this.setState({isLoading: true})
        publishPreset(gameId, presetId).then(
            _ => {
                this.setState({isLoading: false})
            },
            _ => {
                this.setState({isLoading: false})
            }
        )
        return false
    }

}

SendPresetButton.defaultProps = {
    gameId: '',
    record: {},
    icon: <IconSend />
}

SendPresetButton.propTypes = {
    gameId: PropTypes.string.isRequired,
    record: PropTypes.object,
    icon: PropTypes.element
}

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        (props.record == null && nextProps.record != null)
)

export default enhance(SendPresetButton)