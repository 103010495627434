import React, { Children, cloneElement } from 'react'
import IconCancel from '@material-ui/icons/Cancel'
import IconPhotoLibrary from '@material-ui/icons/PhotoLibrary'
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions
} from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core/styles'

const styles = createStyles({
    paperScrollPaper: {
        maxHeight: "none"
    }
})

class UnstyledOpenImageLibraryButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            error: false,
            showDialog: false
        };
    }

    handleClick = () => {
        this.setState({ showDialog: true })
    }

    handleClose = () => {
        this.setState({ showDialog: false })
    }

    render() {
        const { classes, buttonClasses } = this.props
        const { showDialog } = this.state

        const StyledButton = withStyles(buttonClasses)(Button)

        return (
            <React.Fragment>
                <StyledButton
                    color="secondary"
                    onClick={this.handleClick}
                    variant="flat"
                ><IconPhotoLibrary /></StyledButton>
                <Dialog
                    classes={{
                        paperScrollPaper: classes.paperScrollPaper
                    }}
                    open={showDialog}
                    onClose={this.handleClose}
                    fullWidth
                    aria-label="Images"
                >
                    <DialogActions>
                        <Button
                            label="Cancel"
                            onClick={this.handleClose}
                        ><IconCancel /></Button>
                    </DialogActions>
                    <DialogContent style={{ overflowY: "overlay", padding: 0 }}>
                        {cloneElement(
                            Children.only(this.props.children),
                            { close: this.handleClose }
                        )}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }

}

const OpenImageLibraryButton = withStyles(styles)(UnstyledOpenImageLibraryButton)
export default OpenImageLibraryButton