import React from 'react'
import PropTypes from 'prop-types'
import shouldUpdate from 'recompose/shouldUpdate'
import IconDone from  '@material-ui/icons/Done'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { resolvePreset } from '../firebase/actions'
import compose from 'recompose/compose'
import { refreshView as refreshViewAction } from 'ra-core';
import { connect } from 'react-redux'
import LocalizableTextField from './LocalizableTextField'
import get from 'lodash/get'

class ResolvePresetButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = { isLoading: false }
    }

    render() {
        const { gameId, answerIndex, record, icon, ...rest } = this.props
        const { isLoading } = this.state
        const choiceKey = `info.choices[${answerIndex}]`
        return (
            <Button
                style={{textTransform: 'none'}}
                disabled={isLoading || !record.is_enabled || get(record, choiceKey) === undefined}
                label="Resolve"
                onClick={e => this.handleClick(e, gameId, record.id, answerIndex)}
                {...rest}
            >
            { isLoading ? (<CircularProgress size={25} thickness={2} />) : (
                <LocalizableTextField
                    record={record}
                    source={`${choiceKey}.text`}
                />
            ) }
            </Button>
        )
    }

    handleClick(e, gameId, presetId, answerIndex) {
        const { refreshView } = this.props
        e.stopPropagation()

        this.setState({isLoading: true})
        resolvePreset(gameId, presetId, answerIndex).then(
            _ => {
                this.setState({isLoading: false})
                refreshView()
            },
            _ => {
                this.setState({isLoading: false})
            }
        )
        return false
    }

}

ResolvePresetButton.defaultProps = {
    gameId: '',
    answerIndex: 0,
    record: {},
    icon: <IconDone />
}

ResolvePresetButton.propTypes = {
    gameId: PropTypes.string.isRequired,
    answerIndex: PropTypes.number.isRequired,
    refreshView: PropTypes.func.isRequired,
    record: PropTypes.object,
    icon: PropTypes.element
}

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        (props.record == null && nextProps.record != null)
)


export default compose(
    connect(null, { refreshView: refreshViewAction }),
    enhance
)(ResolvePresetButton)