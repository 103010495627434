import React, { Component } from 'react'
import { GET_LIST, GET_MANY, Responsive, withDataProvider } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import TotalBroadcasts from './TotalBroadcasts'
import TotalBuffs from './TotalBuffs'
import TotalUsers from './TotalUsers'
import { db } from '../firebase/config'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' }
}

class Dashboard extends Component {
  state = {}

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.version !== prevProps.version) {
      this.fetchData()
    }
  }

  fetchData() {
    this.fetchTotalUsers()
    this.fetchBroadcasts()
    this.fetchTotalBuffs()
  }

  async fetchTotalUsers() {

    const usersCol = db.collection('users')

    await usersCol
      .get()
      .then((snapshot) => {
        this.setState({
          totalUsers: snapshot.docs.length
        })
      })
      .catch(() => {
        this.setState({
          totalUsers: 0
        })
      })
  }

  async fetchBroadcasts() {
    const col = db.collection('games')

    await col
      .get()
      .then((snapshot) => {
        this.setState({
          totalBroadcasts: snapshot.docs.length
        })
      })
      .catch(() => {
        this.setState({
          totalBroadcasts: 0
        })
      })
  }

  async fetchTotalBuffs() {
    const col = db.collection('games')

    await col.get()
      .then((snapshot) => {
        snapshot.docs.map(game => {
          const gameUid = game.data().uid

          return db.collection(`games/${gameUid}/sent_buffs`)
            .get()
            .then(snap => {
              // console.debug(`sent_buffs for colleciton ${gameUid}`, snap.docs.length)
            })

        })

        this.setState({
          totalBroadcasts: snapshot.docs.length
        })
      })
      .catch(() => {
        this.setState({
          totalBroadcasts: 0
        })
      })
  }

  render() {
    const {
      totalUsers,
      totalBroadcasts
    } = this.state

    const totalBuffs = 3000000

    return (
      <Responsive
        xsmall={
          <div>
            <div style={styles.flexColumn}>
              <div style={styles.flex}>
                <TotalUsers value={totalUsers}/>
                <TotalBroadcasts value={totalBroadcasts}/>
              </div>
              <div style={styles.singleCol}>
                <TotalBuffs value={totalBuffs}/>
              </div>
            </div>
          </div>
        }
        small={
          <div style={styles.flexColumn}>
            <div style={styles.flex}>
              <TotalUsers value={totalUsers}/>
              <TotalBroadcasts value={totalBroadcasts}/>
            </div>
            <div style={styles.singleCol}>
              <TotalBuffs value={totalBuffs}/>
            </div>
          </div>
        }
        medium={
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <TotalUsers value={totalUsers}/>
            </div>
            <div style={styles.leftCol}>
              <TotalBroadcasts value={totalBroadcasts}/>
            </div>
            <div style={styles.rightCol}>
              <TotalBuffs value={totalBuffs}/>
            </div>
          </div>
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion
})

export default compose(
  connect(mapStateToProps),
  withDataProvider
)(Dashboard)
