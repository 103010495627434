class FirebasePath {
    constructor(components) {
        this.components = components || []
    }

    /**
     * @param {FirebasePathComponent} component
     * @returns {FirebasePath}
     */
    pushed(component) {
        let copy = [...this.components]
        copy.push(component)
        return new FirebasePath(copy)
    }
}

class FirebasePathComponent {
    constructor(name, id) {
        this.name = name
        this.id = id
    }
}

export {
    FirebasePath,
    FirebasePathComponent
}