import React, { useEffect, Fragment, useState } from 'react'
import { db } from '../firebase/config'
import * as _ from 'lodash'

/**
 *
 * @param {any} userRef
 * @param {string} broadcastId
 * @param {string} buffId
 * @returns {Promise}
 */
const fetchUserAnsweredBuffs = (userRef, broadcastId, buffId) => {
  let answeredBuffsRef = userRef.collection('answered_buffs')
  answeredBuffsRef = answeredBuffsRef.where('game_id', '==', broadcastId)
  answeredBuffsRef = answeredBuffsRef.where('buff_id', '==', buffId)

  return answeredBuffsRef
		.get()
		.then(userAnsweredBuffsSnapshot => {
      if(userAnsweredBuffsSnapshot.docs.length === 0) {
        return Promise.resolve()
      }
      // answered buffs list for a specifc game/user/buff

      return Promise
        .all(userAnsweredBuffsSnapshot.docs.map(userAnsweredBuff =>  {
          const i = userAnsweredBuff.data()
          i.id = userAnsweredBuff.id
          i.user_id = userRef.id

          return Promise.resolve(i)
        }))

    })
    .catch(err => {
      return Promise.reject(err)
    })
}

const GameStats = (props) => {

  const { id } = props
  const broadcastId = id

  const refUsers = db.collection('users')
  const refBroadcast = db.collection('games').doc(`${broadcastId}`)

  const [stats, setStats] = useState([])

	useEffect(() => {
    refBroadcast
      .get()
      .then((doc) => {
        if(!doc.exists) return Promise.reject(`Broadcast not found, with id ${broadcastId}`)

        return doc.ref
          .collection('sent_buffs')
          .get()
          .then((broadCastBuffsSnapshot) => {
            const list = []
            broadCastBuffsSnapshot.forEach(buff => {
              const b = buff.data()
              b.id = buff.id // attach the id of the buff to the buff
              list.push(b)
            })

            return Promise.resolve(list)
          })
          .then((buffsList) => {

            // get all users
            return refUsers
              .get()
              .then(usersSnapshot => {
                // loop each user
                return Promise.all(usersSnapshot.docs.map(user => {
                  // loop each Buff
                  return Promise.all(buffsList.map((buff) => {
                    return fetchUserAnsweredBuffs(user.ref, broadcastId, buff.id)
                      .then((userBuffs) => {
                        return Promise.resolve(_.compact(userBuffs))
                      })
                  }))
                }))
              })
              .then((d) => {
                // different buffs of same game for multiple users
                // constructur proper stats
                const statx = []

                buffsList.map((b) => {
                  statx[b.id] = {
                    questionTitle: b.info.question.en_US,
                    choices: [],
                    totalVotes: 0,
                  }

                  // generate awnswer pretty object
                  b.info.choices.map((choice, index) => {
                    statx[b.id]['choices'][index] = {
                      label: choice.label,
                      text: choice.text.en_US,
                      points: 0
                    }
                  })

                  d.map(user => {
                    user.map(answerBuff => {

                      if(answerBuff.length == 1) {
                        if (answerBuff[0].buff_id == b.id) {
                          statx[b.id]['totalVotes'] += 1
                          statx[b.id]['choices'][answerBuff[0].answer_index]['points'] += 1
                        }
                      }
                    })
                  })

                })

                setStats(statx)
              })
          })
        })
        .catch(err => {
          return Promise.reject(err)
        })
  }, [])

  // reset indexes
  var statsx = [];
  for (var i in stats) {
    statsx.push(stats[i])
  }

  // console.debug('statsx', statsx)

  const maxTotalAnswers = 3

	return (
   <table className="table-stats">
      <col/>
      <colgroup span="1"></colgroup>
      <colgroup span={maxTotalAnswers * 3}></colgroup>
      <tbody>
      <tr>
        <td rowSpan="2"></td>
        <th colSpan="1" scope="colgroup">Question</th>
        <th colSpan={maxTotalAnswers * 3} scope="colgroup">Answers</th>
      </tr>
      <tr>
        <th scope="col">Total Responses</th>
        {
          [1,2,3].map(answer => {
            return (
              <Fragment key={answer}>
                <th scope="col">Answer {answer}</th>
                <th scope="col">Total Answers {answer}</th>
                <th scope="col">Total Answers {answer} (%)</th>
              </Fragment>
            )
          })
        }
      </tr>

      {statsx && statsx.map((stat, index) => (
        <tr key={index}>
          <th scope="row">{stat.questionTitle}</th>
          <td>{stat.totalVotes}</td>
          {stat.choices && stat.choices.map((answer, k) => {
            return (
              <Fragment key={k}>
                <td title={answer.text}>({answer.label}) {answer.text}</td>
                <td>{answer.points}</td>
                <td>{ answer.points ? (answer.points / stat.totalVotes) * 100 : 0} %</td>
              </Fragment>
            )
          })}

        </tr>
      ))}
      </tbody>
    </table>
	)
}

export default GameStats
