import React, { Component } from 'react'
import { Admin, Resource } from 'react-admin'
import { GameList, GameCreate, GameEdit, GameShow, GameIcon } from './views/Games'
import { GameSetList, GameSetCreate, GameSetEdit, GameSetIcon } from './views/GameSets'
import { UserCreate, UserList, UserShow, UserIcon } from './views/Users'
import { LeaderboardList, LeaderboardIcon } from './views/Leaderboard'
import { PresetBuffCreate } from './components/PresetBuffCreate'
import { PresetBuffEdit } from './components/PresetBuffEdit'
import { Route } from 'react-router'
import firebaseProvider from './firebase/provider'
import get from 'lodash/get'
import './App.css'
import { Login } from './layout'
import authProvider from './authProvider';
import { Dashboard } from './dashboard';

class App extends Component {

  render() {
    return (
      <Admin
        title="Backoffice"
        authProvider={authProvider}
        loginPage={Login}
        dashboard={Dashboard}
        dataProvider={firebaseProvider}
        customRoutes={[
          <Route
            exact
            path="/games/:game_id/preset_buffs/create"
            render={({ match, ...routeProps }) => {
              const gameId = get(match, "params.game_id")
              const basePath = `/games/${gameId}/preset_buffs`
              return (
                <PresetBuffCreate
                  resource="preset_buffs"
                  gameId={gameId}
                  basePath={basePath}
                  {...routeProps}
                />)
            }}
          />,
          <Route
            exact
            path="/games/:game_id/preset_buffs/:preset_id"
            render={(routeProps) => {
              const params = routeProps.match.params
              const gameId = params.game_id
              const presetId = params.preset_id
              const basePath = `/games/${gameId}/preset_buffs`
              return (
                <PresetBuffEdit
                  resource="preset_buffs"
                  gameId={gameId}
                  presetId={presetId}
                  basePath={basePath}
                  {...routeProps}
                />)
            }}
          />
        ]}>
        <Resource
          name="game_lists"
          options={{ label: "Lists" }}
          list={GameSetList}
          create={GameSetCreate}
          edit={GameSetEdit}
          icon={GameSetIcon}
        />
        <Resource
          name="games"
          options={{ label: "Broadcasts" }}
          list={GameList}
          create={GameCreate}
          edit={GameEdit}
          show={GameShow}
          icon={GameIcon}
        />
        <Resource name="users" list={UserList} create={UserCreate} show={UserShow} icon={UserIcon}/>
        <Resource name="preset_buffs"/>
        <Resource name="sent_buffs"/>
        <Resource name="answered_buffs"/>
        <Resource name="images"/>
        <Resource
          name="leaderboard"
          options={{ label: "Ranking" }}
          list={LeaderboardList}
          icon={LeaderboardIcon}
        />
      </Admin>
    )
  }
}

export default App
