export const UPDATE_THEN_CREATE = "UPDATE_THEN_CREATE"
export const crudUpdateThenCreate = (
  resource,
  idToUpdate,
  dataToUpdate,
  previousData,
  dataToCreate,
  redirectTo = "list"
) => ({
  type: UPDATE_THEN_CREATE,
  payload: {
      update: { id: idToUpdate, data: dataToUpdate, previousData },
      create: { data: dataToCreate }
  },
  meta: {
      resource: resource,
      fetch: UPDATE_THEN_CREATE,
      onSuccess: {
          notification: {
              body: 'ra.notification.created',
              level: 'info',
              messageArgs: {
                  smart_count: 1,
              },
          },
          redirectTo
      },
      onFailure: {
          notification: {
              body: 'ra.notification.http_error',
              level: 'warning',
          }
      },
  },
})